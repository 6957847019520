import React, { useState, useEffect, useRef } from 'react';
import { Col, Button, FormGroup, Label, Input, Row, Modal, ModalBody, ModalFooter, Form } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import * as types from '../../../../constants/actionTypes';
import * as lists from '../../../../constants/listType';
import * as validations from '../../../../constants/validations';
import { DataGrid } from '@mui/x-data-grid';
import { NavigationFooter, scrollToTop } from '../Common/NavigationFooter';
import { SubSectionHeader } from '../../../common/SubSectionHeader';
import { FormField } from '../../../common/FormField';
import { FileUpload } from '../../../common/FileUpload';
import { SectionHeader } from '../../../common/SectionHeader';
import { CustomAlert } from '../../../common/CustomAlert';
import { LoadingAnimation } from '../../../common/LoadingAnimation';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputMask from 'react-input-mask';
import { useCookies } from 'react-cookie';
import * as boardCertification from '../../../../constants/specialtyBoardCertification';
import * as suf from '../../../../constants/suffix';
import uuid from 'react-uuid';
import { ClientLoggingModel } from '../../../../models/ClientLoggingModel';
import { Typeahead } from 'react-bootstrap-typeahead';

export const ProviderInformation = (props) => {
    const [cookies, setCookie] = useCookies(['isFormDataSaved']);

    const dispatch = useDispatch();
    const history = useHistory();
    const userInfo = useSelector((state) => state.user.userInfo);
    if (!userInfo) {
        history.push("/");
    }

    const [formIsValid, setFormIsValid] = useState(false);
    const [showAlert, setShowAlert] = useState("");
    const initModal = {
        modalOpen: false,
        modalBody: '',
        modalButtonText: '',
        modalButtonClick: () => { },
        modalHideCancel: false,
    };

    const [pageLoading, setPageLoading] = useState(true);
    const [modal, setModal] = useState(initModal);
    const toggle = () => setModal(initModal);
    const titleOrDegreeList = useSelector((state) => state.professionalApplication.degreeAndCertificationList);
    const titleOrDegreeOptions = titleOrDegreeList
        ? titleOrDegreeList.items.map((s) => { return { value: s.name, label: s.name } })
        : [];
    const providerLanguages = useSelector((state) => state.professionalApplication.languageList);
    const languageList = providerLanguages ?
        providerLanguages.items.map((s) => { return { value: s.name, label: s.name, name: s.name } }) : [];
    const providerFacilityAffiliations = useSelector((state) => state.professionalApplication.providerFacilityAffiliationList);
    const providerFacilityAffiliationList = providerFacilityAffiliations ?
        providerFacilityAffiliations.items.map((s) => { return { value: s.name, label: s.name, name: s.name } }) : [];
    const [buttonAction, setButtonAction] = useState("");
    let professionalApplication = useSelector((state) => { return state.professionalApplication.applicationBeingUpdated; });

    const [locations, setLocations] = useState([]);
    const isFetching = useSelector((state) => state.user.isFetching);
    const npiIsValid = useSelector((state) => state.user.npiIsValid);
    const [npiField, setNpiField] = useState(null);

    const [requiresPriv, setRequiresPriv] = useState(false);
    const specialties = useSelector((state) => state.professionalApplication.specialtyList);
    const specialtiesListOptions = specialties
        ? specialties.items.map((s) => { return { value: s.name, label: s.name } })
        : [];

    const taxonomies = useSelector((state) => state.professionalApplication.taxonomyList);

    const taxonomiesListOptions = taxonomies
        ? taxonomies.items.map((s) => { return { value: s.code, label: s.code } })
        : [];

    const malpracticeInsuranceTypes = useSelector((state) => state.professionalApplication.malpracticeInsuranceTypeList);
    const malpracticeInsuranceTypesOptions = malpracticeInsuranceTypes
        ? malpracticeInsuranceTypes.items.map((s) => { return { value: s.name, label: s.name } })
        : [];

    const malpracticeInsuranceCarriers = useSelector((state) => state.professionalApplication.malpracticeInsuranceCarrierList);
    const malpracticeInsuranceCarriersOptions = malpracticeInsuranceCarriers
        ? malpracticeInsuranceCarriers.items.map((s) => { return { value: s.name, label: s.name } })
        : [];

    const malpracticeInsuranceAmounts = useSelector((state) => state.professionalApplication.malpracticeInsuranceAmountsList);
    const amountsOptions = malpracticeInsuranceAmounts
        ? malpracticeInsuranceAmounts.items.map((s) => { return { value: s.name, label: s.name } })
        : [];

    const [providersList, setProvidersList] = useState(professionalApplication?.providers ? professionalApplication?.providers : []);
    const [providerRemovedFromGrid, setProviderRemovedFromGrid] = useState(false);
    const [rowIdBeingEdited, setRowIdBeingEdited] = useState(null);
    const [rows, setRows] = useState([]);
    const [isGridLoaded, setIsGridLoaded] = useState(false);
    const [clientLoggingModel, setClientLoggingModel] = useState(ClientLoggingModel);

    const newProviderObj = {
        providerGUID: null,
        firstName: null,
        middleInitial: null,
        lastName: null,
        suffix: null,
        npi: null,
        ssn: null,
        medicaidId: null,
        medicareId: null,
        licenseNumber: null,
        licenseNumberEffectiveDate: null,
        licenseNumberExpirationDate: null,
        deaNumber: null,
        deaNumberEffectiveDate: null,
        deaNumberExpirationDate: null,
        titleOrDegree: null,
        languages: [],
        birthDate: null,
        gender: null,
        isPcp: null,
        providerPrimarySpecialty: null,
        primaryTaxonomy: null,
        providerSecondarySpecialty: null,
        secondaryTaxonomy: null,
        boardCertification: null,
        currentRadiologyCertificate: null,
        currentStateLicense: null,
        isHospitalBased: null,

        caqhProviderId: null,
        facilityAffiliations: [],
        hasAdmittingPrivileges: null,
        primarySpecialtyBoardCertificate: null,
        secondarySpecialtyBoardCertificate: null,
        primaryLocation: null,
        additionalLocations: [],
        nonCaqhAttachments: {
            irsW9: null,
            deaRegistration: null,
            curriculumVitae: null,
            credentialingApplication: null
        },
        primarySupervisingPhysician: {
            midlevelProtocolsAvailableOnRequest: null,
            name: null,
            npi: null,
            specialty: null,
            isPhysicianContracted: null
        },
        malpracticeInsurance: {
            carrier: null,
            otherCarrier: null,
            type: null,
            malpracticeEffectiveDate: null,
            malpracticeExpirationDate: null,
            amounts: null,
            malpracticePolicy: null
        }
    };
    const [provider, setProvider] = useState(newProviderObj);
    const [primaryTaxonomyValue, setPrimaryTaxonomyValue] = useState("");
    const [secondaryTaxonomyValue, setSecondaryTaxonomyValue] = useState("");
    const initialErrorObj = {
        firstName: '',
        middleInitial: '',
        lastName: '',
        npi: '',
        ssn: '',
        duplicateProvider: '',
        medicaidId: '',
        medicareId: '',
        licenseNumber: '',
        licenseNumberEffectiveDate: '',
        licenseNumberExpirationDate: '',
        deaNumber: '',
        deaNumberEffectiveDate: '',
        deaNumberExpirationDate: '',
        degree: '',
        birthDate: '',
        gender: '',

        facilityAffiliations: '',
        caqhProviderId: '',
        primaryLocation: '',
        additionalLocations: '',

        physicianName: '',
        physicianNpi: '',
        carrier: '',
        otherCarrier: '',
        type: '',
        malpracticeEffectiveDate: '',
        malpracticeExpirationDate: '',
        amounts: '',
        primaryTaxonomy: '',
        secondaryTaxonomy: '',
        providerPrimarySpecialty:'',
        providerSecondarySpecialty:'',
        boardCertification: '',
        currentRadiologyCertificate: '',
        currentStateLicense: '',
        irsW9: '',
        deaRegistration: '',
        curriculumVitae: '',
        credentialingApplication: '',
        malpracticePolicy: ''
    };
    const [errors, setErrors] = useState(initialErrorObj);
    const [requiredErrors, setRequiredErrors] = useState(initialErrorObj);

    const [languages, setLanguages] = useState("Select Value(s)");
    const [additionalLocations, setAdditionalLocations] = useState("Select Value(s)");
    const [affiliations, setAffiliations] = useState("Select Value(s)");
    const [selectedAffiliations, setSelectedAffiliations] = useState([]);
    const [isTitleOrDegreeActive, setIsTitleOrDegreeActive] = useState(false);
    const [isRequiredDEADates, setIsRequiredDEADates] = useState(false);
    const [showRequiredFieldErrorInitially, setShowRequiredFieldErrorInitially] = useState(false);

    const lastAttachment = useSelector((state) => { return state.document.attachment; });
    const documentApiError = useSelector((state) => {
        return state.document.error;
    });
    const documentIsFetching = useSelector((state) => {
        return state.document.isFetching;
    });
    const uploadOccuredAt = useSelector((state) => {
        return state.document.uploadOccuredAt;
    });
    const apiCallSuccess = useSelector((state) => state.professionalApplication.applicationApiCalledSuccess);
    const apiErrors = useSelector((state) => state.professionalApplication.error);

    const requiredFields = [];

    useEffect(() => {
        if (userInfo) {
            setCookie('isFormDataSaved', 'true', { path: '/', maxAge: 31536000 }); //maxAge - 31536000 sconds / 525,600 minutes / 365 Days
            dispatch({ type: types.APPLICATION_GET_REFERENCE_LIST_START, listname: lists.ListType.DegreeAndCertificationList });
            dispatch({ type: types.APPLICATION_GET_REFERENCE_LIST_START, listname: lists.ListType.LanguageList });
            dispatch({ type: types.APPLICATION_GET_REFERENCE_LIST_START, listname: lists.ListType.SpecialtyList });
            dispatch({ type: types.APPLICATION_GET_REFERENCE_LIST_START, listname: lists.ListType.TaxonomyList });
            dispatch({ type: types.APPLICATION_GET_REFERENCE_LIST_START, listname: lists.ListType.ProviderFacilityAffiliationList });
            dispatch({ type: types.APPLICATION_GET_REFERENCE_LIST_START, listname: lists.ListType.MalpracticeInsuranceTypeList });
            dispatch({ type: types.APPLICATION_GET_REFERENCE_LIST_START, listname: lists.ListType.MalpracticeInsuranceCarrierList });
            dispatch({ type: types.APPLICATION_GET_REFERENCE_LIST_START, listname: lists.ListType.MalpracticeInsuranceAmountsList });

            // Locations drop down
            if (professionalApplication?.practiceLocations) {
                var arr = [];
                var primaryLoc = professionalApplication.practiceLocations.find(l => l.type === 'Primary');
                if (primaryLoc)
                    arr.push({ name: primaryLoc.city + ", " + primaryLoc.state + ", " + primaryLoc.zipCode + "-" + primaryLoc.zipPlus4 + ", " + primaryLoc.streetLine1, value: primaryLoc.practiceLocationId });
                var alternateLocs = professionalApplication.practiceLocations.filter(l => l.type === 'Alternate');
                if (alternateLocs.length > 0) {
                    var list = alternateLocs.map(function (l) { return ({ name: l.city + ", " + l.state + ", " + l.zipCode + "-" + l.zipPlus4 + ", " + l.streetLine1, value: l.practiceLocationId }); });
                    list.sort();
                    arr = arr.concat(list);
                }
                const locs = arr
                    ? arr.map((s) => { return { value: s.value, label: s.name, name: s.name } })
                    : [];
                setLocations(locs);
            }
        }
    }, []);

    useEffect(() => {
        if (titleOrDegreeList && providerLanguages && specialties && taxonomies && providerFacilityAffiliations && malpracticeInsuranceTypes
            && malpracticeInsuranceCarriers && malpracticeInsuranceAmounts && isGridLoaded) {
            setPageLoading(false);
        }
    }, [titleOrDegreeList, providerLanguages, specialties, taxonomies, providerFacilityAffiliations, malpracticeInsuranceTypes, malpracticeInsuranceCarriers, malpracticeInsuranceAmounts])

    useEffect(() => {
        if (titleOrDegreeList) {
            // Populate grid - NeedtitleOrDegree list before we start populating grid(for isValidProvider() operation)
            let rowArray = [];
            if (professionalApplication?.providers) {
                let list = professionalApplication?.providers;
                for (var i = 0; i < list.length; i++) {
                    rowArray.push({
                        id: list[i].providerGUID,
                        lastName: list[i].lastName,
                        middleInitial: list[i].middleInitial,
                        firstName: list[i].firstName,
                        npi: list[i].npi,
                        ssn: list[i].ssn,
                        validProvider: isValidProvider(list[i]) ? 'Y' : 'N'
                    });

                }
            }
            setRows(rowArray);
            setIsGridLoaded(true);
        }
    }, [titleOrDegreeList]);

    useEffect(() => {
        let valid = validations.validateForm(errors);
        setFormIsValid(valid && isValidProvider(provider));
    }, [errors, provider, rows]);

    useEffect(() => {
        var lang = provider.languages.length === 0 ? "Select Value(s)" : provider.languages.join(", ");
        setLanguages(lang);
    }, [provider.languages]);

    useEffect(() => {
        let addresses = [];
        if (provider.additionalLocations?.length > 0) {
            addresses = provider.additionalLocations?.map(loc => {
                var l = professionalApplication?.practiceLocations.filter(k => k.practiceLocationId === loc);
                if (l && l.length > 0) {
                    return l[0].city + ", " + l[0].state + ", " + l[0].zipCode + "-" + l[0].zipPlus4 + ", " + l[0].streetLine1;

                }
            });
        }
        var addLoc = addresses?.length > 0 ? addresses.join(", ") : "Select Value(s)";
        setAdditionalLocations(addLoc);
    }, [provider.additionalLocations]);

    useEffect(() => {
        // TODO:  Added the null check to top the client from crashing. Need to determine whether it was due to bad data, or if the check should remain
        if (provider.facilityAffiliations != null) {
            var vals = provider.facilityAffiliations.length === 0 ? "Select Value(s)" : Array.from(provider.facilityAffiliations, option => option.name).join(", ");
            setAffiliations(vals);
            setSelectedAffiliations(vals);

            let errs = { ...errors };
            let selected = Array.from(provider.facilityAffiliations, option => option.name);
            errs.facilityAffiliations = "";

            if (!provider.hasAdmittingPrivileges && requiresPriv) {
                errs.facilityAffiliations = "In Network Hospital Privileges are required for network participation";
            } else if (provider.hasAdmittingPrivileges && selected.length === 0) {
                errs.facilityAffiliations = 'At least one affiliation is required if you have admitting privileges!';
            }

            setErrors(errs);
        }
    }, [provider.facilityAffiliations]);

    useEffect(() => {
        if (apiCallSuccess && professionalApplication) {
            dispatch({ type: types.REFRESH_APPLICATION_API_CALL_FLAG });
            if (buttonAction === "saveAndNewClick") {
                setCurrentProvider(newProviderObj);
                setRequiredErrors(initialErrorObj);
                setErrors(initialErrorObj);
                setRowIdBeingEdited(null);
                scrollToTop();
                setRequiresPriv(false);
                setShowAlert("success");
            } else if (buttonAction === "submitClick") {
                setModal({
                    modalOpen: true,
                    modalBody: 'Success! Thank you for your interest in becoming a contracted provider. Please allow 30-45 days before checking on status.',
                    modalButtonText: 'Okay',
                    modalButtonClick: modalApplicationSubmitted,
                    modalHideCancel: true,
                });
            } else if (buttonAction === "backButtonClick") {
                setRequiresPriv(false);
                history.push("/practiceContactInfo");
            } else {
                scrollToTop();
                setShowAlert("success");
            }
        } else if (apiErrors) {
            scrollToTop();
            setShowAlert("danger");
        }
        setButtonAction("");
    }, [apiCallSuccess, apiErrors]);
    useEffect(() => {
        if (!isFetching && npiField) {
            setErrors({ ...errors, [npiField]: npiIsValid });
            setNpiField(null);
        }
    }, [npiIsValid]);

    const modalApplicationSubmitted = () => { //SUBMITTED return to dashboard
        dispatch({ type: types.STORE_APPLICATION, applicationBeingUpdated: null });
        history.push("/Dashboard");
    }

    const onFocusOut = (event) => {
        const { name, value } = event.target;
        let newValue = value.trim();
        let errs = { ...errors };
        switch (name) {
            case 'firstName':
                errs.firstName = validations.validateLength(name, newValue, 15, 0) || validations.validateNA(newValue);
                break;
            case 'middleInitial':
                errs.middleInitial = validations.validateLength(name, newValue, 1, 0);
                break;
            case 'lastName':
                errs.lastName = validations.validateLength(name, newValue, 60, 0) || validations.validateNA(newValue);
                break;
            case 'deaNumber':
                errs.deaNumber = validations.validateLength(name, newValue, 20, 0);
                break;
            case 'physicianName':
                var min = isTitleOrDegreeActive ? 1 : 0;
                errs.physicianName = validations.validateLength(name, newValue, 20, min) || validations.validateNA(newValue);
                break;
            default:
                break;
        }
        if (name === 'physicianName') {
            setProvider({ ...provider, primarySupervisingPhysician: { ...provider.primarySupervisingPhysician, name: newValue } });
        }
        else {
            setProvider({ ...provider, [name]: newValue });
        }
        setErrors(errs);
    }

    const updateRequiredErrors = (name, testValue, label) => {
        if (!testValue) {
            setRequiredErrors({ ...requiredErrors, [name]: `${label} is required` });
        }
        else {
            setRequiredErrors({ ...requiredErrors, [name]: '' });
        }
    }

    const titleOrDegreeInputRef = useRef();
    const [TitleDegreeTypeahead, setTitleDegreeTypeahead] = useState(
        provider.titleOrDegree ? [provider.titleOrDegree] : []
    );
    const onTitleDegreeChange = (selected) => {
        setTitleDegreeTypeahead(selected)
        updateRequiredErrors("titleOrDegree", selected[0]?.value, "Title/Degree");
        setProvider({ ...provider, ["titleOrDegree"]: selected[0]?.value });
        let title = titleOrDegreeList ? titleOrDegreeList.items.find((s) => { return s.name === selected[0]?.value }) : null;
        setIsTitleOrDegreeActive(title ? title.supervisorInfoRequired : false);
    }

    const languageInputRef = useRef();
    const [LanguageTypeahead, setLanguageTypeahead] = useState([]);
    const onLanguageTypeaheadChange = (selected) => {
        setLanguageTypeahead(selected)
        const selectedLanguages = selected.map(item => item.value);
        setProvider({ ...provider, ['languages']: selectedLanguages });
    }

    const providerPrimarySpecialtyInputRef = useRef();
    const [ProviderPrimarySpecialtyTypeahead, setProviderPrimarySpecialtyTypeahead] = useState(
        provider.providerPrimarySpecialty?.name ? [provider.providerPrimarySpecialty.name] : []
    );
    const onProviderPrimarySpecialtyChange = (selected) => {
        setProviderPrimarySpecialtyTypeahead(selected)

        const selectedSpecialtyName = selected[0]?.value;

        updateRequiredErrors("providerPrimarySpecialty", selectedSpecialtyName, "Primary Specialty");

        let primarySpecialty = specialties.items.find((s) => { return s.name === selectedSpecialtyName });

        let errs = { ...errors };

        errs.facilityAffiliations = "";
        setRequiresPriv(false);

        if (primarySpecialty && primarySpecialty.requiresPrivs) {
            setRequiresPriv(true);
            if (!provider.hasAdmittingPrivileges) {
                errs.facilityAffiliations = "In Network Hospital Privileges are required for network participation";
            }
        }

        errs.providerSecondarySpecialty = provider.providerSecondarySpecialty ? '' : errs.providerSecondarySpecialty;
        errs.providerPrimarySpecialty = '';

        if (selectedSpecialtyName === provider.providerSecondarySpecialty?.name) {
            errs.providerPrimarySpecialty = 'Primary specialty and secondary specialty cannot be same';
        }

        setErrors(errs);
        setProvider({ ...provider, ["providerPrimarySpecialty"]: primarySpecialty }); 
    }

    const providerSecondarySpecialtyInputRef = useRef();
    const [ProviderSecondarySpecialtyTypeahead, setProviderSecondarySpecialtyTypeahead] = useState(
        provider.providerSecondarySpecialty?.name ? [provider.providerSecondarySpecialty.name] : []
    );
    const onProviderSecondarySpecialtyChange = (selected) => {

        setProviderSecondarySpecialtyTypeahead(selected)

        const selectedSpecialtyName = selected[0]?.value;
        let errs = { ...errors };
        let secondarySpecialty = specialties.items.find((s) => { return s.name === selectedSpecialtyName });

        updateRequiredErrors("providerSecondarySpecialty", selectedSpecialtyName, "Secondary Specialty");

        errs.providerPrimarySpecialty = provider.providerPrimarySpecialty ? '' : errs.providerPrimarySpecialty;
        errs.providerSecondarySpecialty = '';

        if (provider.providerPrimarySpecialty?.name === selectedSpecialtyName) {
            errs.providerSecondarySpecialty = 'Primary specialty and secondary specialty cannot be same';
        }

        setErrors(errs);
        setProvider({ ...provider, ["providerSecondarySpecialty"]: secondarySpecialty });
    }

    const physicianSpecialtyInputRef = useRef();
    const [PhysicianSpecialtyTypeahead, setPhysicianSpecialtyTypeahead] = useState(
        provider.primarySupervisingPhysician?.specialty ? [provider.primarySupervisingPhysician.specialty] : []
    );
    const onPhysicianSpecialtyChange = (selected) => {
        setPhysicianSpecialtyTypeahead(selected)
        updateRequiredErrors("physicianSpecialty", selected[0]?.value, "Physician Specialty");

        const selectedSpecialtyName = selected[0]?.value;
        setProvider({ ...provider, primarySupervisingPhysician: { ...provider.primarySupervisingPhysician, specialty: selectedSpecialtyName } });
    }

    const carrierInputRef = useRef();
    const [CarrierTypeahead, setCarrierTypeahead] = useState(
        provider.malpracticeInsurance?.carrier?.name ? [provider.malpracticeInsurance.carrier.name] : []
    );
    const onCarrierChange = (selected) => {
        setCarrierTypeahead(selected)

        const value = selected[0]?.value;
        setProvider({ ...provider, primarySupervisingPhysician: { ...provider.primarySupervisingPhysician, specialty: value } });
        updateRequiredErrors('carrier', value, "Malpractice Insurance Carrier");
        const insuranceCarrier = malpracticeInsuranceCarriers?.items.find(function (c) {
            return c.name === value
        });
        setProvider({ ...provider, malpracticeInsurance: { ...provider.malpracticeInsurance, ['carrier']: insuranceCarrier, otherCarrier: (value === 'Other') ? provider.malpracticeInsurance?.otherCarrier : null } });
    }

    const facilityAffiliationsInputRef = useRef();
    const [FacilityAffiliationsTypeahead, setFacilityAffiliationsTypeahead] = useState([]);
    const onFacilityAffiliationsTypeaheadChange = (selected) => {
        setFacilityAffiliationsTypeahead(selected)
        setProvider({ ...provider, ['facilityAffiliations']: selected });
    }

    const primaryTaxonomyInputRef = useRef();
    const [primaryTaxonomyTypeahead, setPrimaryTaxonomyTypeahead] = useState([]);
    const onPrimaryTaxonomyChange = (selected) => {
        setPrimaryTaxonomyTypeahead(selected)

        const name = 'primaryTaxonomy'
        const value = selected[0]?.value;
        let errs = { ...errors };

        updateRequiredErrors(name, value, "Primary Taxonomy");
        let taxonomy = taxonomies.items.find((t) => { return t.code.toLowerCase() === value?.toLowerCase() });
        let taxonomyValue = taxonomy ? taxonomy.code : value;
        let duplicateTaxonomyError = 'Primary Taxonomy and Secondary Taxonomy cannot be same';

        errs.secondaryTaxonomy = provider.secondaryTaxonomy ? '' : errs.secondaryTaxonomy;

        errs.primaryTaxonomy = '';
        if (!taxonomy) {
            errs.primaryTaxonomy = "Not a valid Taxonomy, please choose another";
        }
        else if (taxonomy?.code === provider.secondaryTaxonomy?.code) {
            errs.primaryTaxonomy = duplicateTaxonomyError;
        }

        setErrors(errs);
        setPrimaryTaxonomyValue(taxonomyValue);
        setProvider({ ...provider, [name]: taxonomy });
    }

    const secondaryTaxonomyInputRef = useRef();
    const [secondaryTaxonomyTypeahead, setSecondaryTaxonomyTypeahead] = useState([]);
    const onSecondaryTaxonomyChange = (selected) => {
        setSecondaryTaxonomyTypeahead(selected);
        const name = 'secondaryTaxonomy'
        const value = selected[0]?.value;
        let errs = { ...errors };

        let secondTaxonomy = taxonomies.items.find((t) => { return t.code.toLowerCase() === value?.toLowerCase() });
        let secondTaxonomyValue = secondTaxonomy ? secondTaxonomy.code : value;

        errs.primaryTaxonomy = provider.primaryTaxonomy ? '' : errs.primaryTaxonomy;

        errs.secondaryTaxonomy = '';

        if (provider.primaryTaxonomy?.code === secondTaxonomy?.code) {
            errs.secondaryTaxonomy = 'Primary Taxonomy and Secondary Taxonomy cannot be same';
        }

        setErrors(errs);
        setSecondaryTaxonomyValue(secondTaxonomyValue);
        setProvider({ ...provider, [name]: secondTaxonomy });
       
    }

    const onTypeaheadBlur = (typeaheadInputRef, typeaheadOptions, typeaheadName, isMultiple) => {
        var typeaheadInput = typeaheadInputRef.current.state.text;
        if (typeaheadInput) {
            var newItem = typeaheadOptions.find((s) => { return (s.value.toLowerCase() === typeaheadInput.toLowerCase() || s.label.toLowerCase() === typeaheadInput.toLowerCase()) })
            var setItems = newItem ? [newItem] : null;
            if (isMultiple) {
                var selectedItems = typeaheadInputRef.current.state.selected;
                if (newItem) {
                    var alreadyPresent = selectedItems.some(function (item) {
                        return item.value === newItem.value;
                    });
                    if (!alreadyPresent) {
                        selectedItems.push(newItem);
                    }
                }
                typeaheadInputRef.current.state.text = "";
                setItems = selectedItems;
            }

            if (setItems) {
                switch (typeaheadName) {
                    case 'TitleDegree':
                        onTitleDegreeChange(setItems);
                        break;
                    case 'Language':
                        onLanguageTypeaheadChange(setItems);
                        break;
                    case 'ProviderPrimarySpecialty':
                        onProviderPrimarySpecialtyChange(setItems);
                        break;
                    case 'ProviderSecondarySpecialty':
                        onProviderSecondarySpecialtyChange(setItems);
                        break;
                    case 'PhysicianSpecialty':
                        onPhysicianSpecialtyChange(setItems);
                        break;
                    case 'Carrier':
                        onCarrierChange(setItems);
                        break;
                    case 'FacilityAffiliations':
                        onFacilityAffiliationsTypeaheadChange(setItems);
                        break;
                    case 'PrimaryTaxonomy':
                        onPrimaryTaxonomyChange(setItems);
                        break;
                    case 'SecondaryTaxonomy':
                        onSecondaryTaxonomyChange(setItems);
                        break;
                    default:
                        break;
                }
            }
            else {
                typeaheadInputRef.current.clear();
            }
        }
    }

    const saveInputs = (event) => {
        const { name, value } = event.target;
        let message = "";
        let methodName = "";
        let jsonResult = "";
        let errs = { ...errors };
        switch (name) {
            case 'firstName':
                updateRequiredErrors(name, value, "First Name");
                errs.firstName = !value || validations.validateLength(name, value, 15, 1) || validations.validateNA(value);
                break;
            case 'middleInitial':
                errs.middleInitial = validations.validateLength(name, value, 1, 0);
                break;
            case 'lastName':
                updateRequiredErrors(name, value, "Last Name");
                errs.lastName = !value || validations.validateLength(name, value, 60, 1) || validations.validateNA(value);
                break;
            case 'npi':
                updateRequiredErrors(name, value, "NPI");
                errs.npi = validations.validateNpi(value);
                errs.duplicateProvider = errs.npi === '' ? validateDuplicateProvider(value) : '';

                if (value == provider.primarySupervisingPhysician.npi) {
                    errs.npi = "NPI must be different from supervising physician";
                }
                if (value && errs.duplicateProvider === '' && errs.npi === '') {
                    setNpiField("npi");
                    message = "Performing API call to validate NPI " + value + " for provider.";
                    methodName = "saveInputs";
                    jsonResult = JSON.stringify(value);
                    logInfoWrite(message, methodName, jsonResult);
                    dispatch({ type: types.SIGNUP_GET_NPI_START, npi: value });
                }
                break;
            case 'ssn':
                updateRequiredErrors(name, value, "Social Security Number");
                errs.ssn = validations.validateSSN(value);
                break;
            case 'medicaidId':
                errs.medicaidId = validations.validateMedicaid(value);
                break;
            case 'medicareId':
                errs.medicareId = validations.validateMedicareId(value);
                break;
            case 'licenseNumber':
                updateRequiredErrors(name, value, "License Number");
                errs.licenseNumber = validations.validateLicenseNumber(value);
                break;
            case 'deaNumber':
                errs.deaNumber = validations.validateLength(name, value, 20, 0);
                if (value) {
                    setIsRequiredDEADates(true);
                    setProvider({ ...provider, [name]: value });
                }
                else {
                    setIsRequiredDEADates(false);
                    setProvider({
                        ...provider,
                        deaNumber: value,
                        deaNumberEffectiveDate: null,
                        deaNumberExpirationDate: null,

                    });
                    setRequiredErrors({
                        ...requiredErrors,
                        deaNumberEffectiveDate: '',
                        deaNumberExpirationDate: '',
                    });
                }
                break;
            case 'gender':
                updateRequiredErrors(name, value, "Gender");
                setProvider({ ...provider, [name]: event.target.id });
                break;
            case 'isPcp':
                updateRequiredErrors(name, value, "Primary Care Provider");
                setProvider({ ...provider, [name]: (value === "Y" ? true : false) });
                break;
            case 'isHospitalBased':
                updateRequiredErrors(name, value, "Hospital Based");
                setProvider({ ...provider, [name]: (value === "Y" ? true : false) });
                break;
            case 'caqhProviderId':
                errs.caqhProviderId = validations.validateCaqh(value);
                break;
            case 'hasAdmittingPrivileges':
                updateRequiredErrors(name, value, "Admitting Privileges");

                errs.facilityAffiliations = "";
                if (value === "N" && requiresPriv) {
                    errs.facilityAffiliations = "In Network Hospital Privileges are required for network participation";
                } else if (value === "Y" && provider.facilityAffiliations.length === 0) {
                    errs.facilityAffiliations = 'At least one affiliation is required if you have admitting privileges!';
                }
                setProvider({ ...provider, [name]: (value === "Y" ? true : false) });
                break;
            case 'primaryLocation':
                updateRequiredErrors(name, value, "Primary Location");
                var addLoc = provider.additionalLocations;
                if ((addLoc?.indexOf(value)) > -1) {
                    alert("Primary Location cannot be the same as an Additional Location");
                } else {
                    setProvider({ ...provider, [name]: value });
                }
                break;
            case 'additionalLocations':
                var addLoc = provider.additionalLocations;
                if ((addLoc?.indexOf(event.target.id)) > -1) {
                    if (!event.target.checked) {
                        let filteredArray = provider.additionalLocations.filter(item => item !== event.target.id);
                        setProvider({ ...provider, [name]: filteredArray });
                    }
                }
                else {
                    if (provider.primaryLocation === event.target.id) {
                        alert("Primary Location cannot be the same as an Additional Location");
                    }
                    else {
                        errs.additionalLocations = '';
                        setProvider({ ...provider, [name]: [...provider.additionalLocations, event.target.id] });
                    }
                }
                break;
            case 'midlevelProtocolsAvailableOnRequest':
                setProvider({ ...provider, primarySupervisingPhysician: { ...provider.primarySupervisingPhysician, [name]: (value === "Y" ? true : false) } });
                break;
            case 'physicianName':
                var min = isTitleOrDegreeActive ? 1 : 0;
                errs.physicianName = validations.validateLength(name, value, 20, min);
                setProvider({ ...provider, primarySupervisingPhysician: { ...provider.primarySupervisingPhysician, name: value } });
                break;
            case 'physicianNpi':
                errs.physicianNpi = validations.validateNpi(value);

                if (value == provider.npi) {
                    errs.physicianNpi = "NPI must be different from applicant's NPI"
                }

                if (errs.physicianNpi === '') {
                    setNpiField("physicianNpi");
                    message = "Performing API call to validate NPI " + value + " for physician.";
                    methodName = "saveInputs";
                    jsonResult = JSON.stringify(value);
                    logInfoWrite(message, methodName, jsonResult);
                    dispatch({ type: types.SIGNUP_GET_NPI_START, npi: value });
                }
                setProvider({ ...provider, primarySupervisingPhysician: { ...provider.primarySupervisingPhysician, npi: value } });
                break;
            case 'isPhysicianContracted':
                setProvider({ ...provider, primarySupervisingPhysician: { ...provider.primarySupervisingPhysician, [name]: (value === "Y" ? true : false) } });
                break;
            case 'type':
                updateRequiredErrors(name, value, "Malpractice Insurance Type");
                const insuranceType = malpracticeInsuranceTypes?.items.find(function (c) {
                    return c.name === value
                });
                setProvider({ ...provider, malpracticeInsurance: { ...provider.malpracticeInsurance, [name]: insuranceType } });
                break;
            case 'amounts':
                updateRequiredErrors(name, value, "Malpractice Insurance Amounts");
                setProvider({ ...provider, malpracticeInsurance: { ...provider.malpracticeInsurance, [name]: value } });
                break;
            case 'otherCarrier':
                setProvider({ ...provider, malpracticeInsurance: { ...provider.malpracticeInsurance, [name]: value } });
                break;
            default:
                break;

        }
        setCookie('isFormDataSaved', 'false', { path: '/', maxAge: 31536000 });

        if (!["titleOrDegree", "languages", "gender", "providerPrimarySpecialty", "primaryTaxonomy", "providerSecondarySpecialty", "secondaryTaxonomy",
            "isPcp", "isHospitalBased", "facilityAffiliations", "hasAdmittingPrivileges",
            "midlevelProtocolsAvailableOnRequest", "physicianName", "physicianNpi", "physicianSpecialty", "isPhysicianContracted",
            "type", "carrier", "otherCarrier", "amounts", "primaryLocation", "additionalLocations", "deaNumber"].includes(name)) {
            setProvider({ ...provider, [name]: value });
        }
        setErrors(errs);

    }

    const setCurrentProvider = (p) => {
        setProvider(p);
        setPrimaryTaxonomyValue(p.primaryTaxonomy ? p.primaryTaxonomy?.code : "");
        setSecondaryTaxonomyValue(p.secondaryTaxonomy ? p.secondaryTaxonomy?.code : "");
        let title = titleOrDegreeList ? titleOrDegreeList.items.find((s) => { return s.name === p?.titleOrDegree }) : null;
        setIsTitleOrDegreeActive(title ? title.supervisorInfoRequired : false);

        setTitleDegreeTypeahead(p.titleOrDegree ? [p.titleOrDegree] : [])
        setProviderPrimarySpecialtyTypeahead(p.providerPrimarySpecialty?.name ? [p.providerPrimarySpecialty.name] : [])
        setProviderSecondarySpecialtyTypeahead(p.providerSecondarySpecialty?.name ? [p.providerSecondarySpecialty.name] : [])
        setPhysicianSpecialtyTypeahead(p.primarySupervisingPhysician?.specialty ? [p.primarySupervisingPhysician.specialty] : [])
        setCarrierTypeahead(p.malpracticeInsurance?.carrier?.name ? [p.malpracticeInsurance?.carrier?.name] : [])

        let languages = p.languages.map(language => {
            return {
                value: language,
                label: language,
                name: language
            };
        });

        setLanguageTypeahead(languages)


        let facilityAffiliations = p.facilityAffiliations.map(fa => {
            return {
                value: fa.name,
                label: fa.name,
                name: fa.name
            };
        });

        setFacilityAffiliationsTypeahead(facilityAffiliations)

        let primaryTaxonomyValue = p.primaryTaxonomy
            ? {
                value: p.primaryTaxonomy.code,
                label: p.primaryTaxonomy.code,
                name: p.primaryTaxonomy.code
            }
            : {};

        setPrimaryTaxonomyTypeahead(p.primaryTaxonomy ? [primaryTaxonomyValue] : []);

        let secondaryTaxonomyValue = p.secondaryTaxonomy
            ? {
                value: p.secondaryTaxonomy.code,
                label: p.secondaryTaxonomy.code,
                name: p.secondaryTaxonomy.code
            }
            : {};

        setSecondaryTaxonomyTypeahead(p.secondaryTaxonomy ? [secondaryTaxonomyValue] : []);

    }

    const saveBirthDate = (value) => {
        const today = new Date();
        const enteredDate = new Date(value);

        if (enteredDate > today) {
            let errs = { ...errors };
            errs.birthDate = "Date of birth cannot be a future date.";
            setErrors(errs);
            setProvider({ ...provider, birthDate: null });
        } else {
            updateRequiredErrors("birthDate", value, "Date of Birth");
            let errs = { ...errors };
            errs.birthDate = validations.validateDate(value);
            setErrors(errs);
            setProvider({ ...provider, birthDate: value === "" ? null : value });
        }
    }

    const saveEffectiveDate = (name, value, label) => {
        if (name === "deaNumberEffectiveDate") {
            if (provider.deaNumber) {
                updateRequiredErrors(name, value, label);
            }
        }
        else {
            updateRequiredErrors(name, value, label);
        }
        let errs = { ...errors };
        let error = validations.validateDate(value);
        switch (name) {
            case 'malpracticeEffectiveDate':
                errs.malpracticeEffectiveDate = error;
                if (provider.malpracticeInsurance?.malpracticeExpirationDate) {
                    let error = validations.validateDateRange(value, provider.malpracticeInsurance?.malpracticeExpirationDate);
                    errs.malpracticeExpirationDate = error;
                }
                setProvider({ ...provider, malpracticeInsurance: { ...provider.malpracticeInsurance, malpracticeEffectiveDate: value === "" ? null : value } });

                break;
            case 'licenseNumberEffectiveDate':
                errs.licenseNumberEffectiveDate = error;
                if (provider.licenseNumberExpirationDate) {
                    let error = validations.validateDateRange(value, provider.licenseNumberExpirationDate);
                    errs.licenseNumberExpirationDate = error;
                }
                setProvider({ ...provider, [name]: value === "" ? null : value });
                break;
            case 'deaNumberEffectiveDate':
                errs.deaNumberEffectiveDate = error;
                if (provider.deaNumberExpirationDate) {
                    let error = validations.validateDateRange(value, provider.deaNumberExpirationDate);
                    errs.deaNumberExpirationDate = error;
                }
                setProvider({ ...provider, [name]: value === "" ? null : value });
                break;
            default:
                break;
        }

        setErrors({ ...errs });

    }

    const saveExpirationDate = (name, value, label) => {
        if (name === "deaNumberExpirationDate") {
            if (provider.deaNumber) {
                updateRequiredErrors(name, value, label);
            }
        }
        else {
            updateRequiredErrors(name, value, label);
        }
        let errs = { ...errors };
        let error = validations.validateDate(value);
        switch (name) {
            case 'malpracticeExpirationDate':
                errs.malpracticeExpirationDate = error;
                if (error === '') {
                    error = validations.validateExpirationDate(value);
                    if (error === '' && provider.malpracticeInsurance?.malpracticeEffectiveDate) {
                        error = validations.validateDateRange(provider.malpracticeInsurance?.malpracticeEffectiveDate, value);
                    }
                    errs.malpracticeExpirationDate = error;
                }
                setProvider({ ...provider, malpracticeInsurance: { ...provider.malpracticeInsurance, malpracticeExpirationDate: value === "" ? null : value } });

                break;
            case 'licenseNumberExpirationDate':
                errs.licenseNumberExpirationDate = error;
                if (error === '') {
                    error = validations.validateExpirationDate(value);
                    if (error === '' && provider.licenseNumberEffectiveDate) {
                        error = validations.validateDateRange(provider.licenseNumberEffectiveDate, value);
                    }
                    errs.licenseNumberExpirationDate = error;
                }
                setProvider({ ...provider, [name]: value === "" ? null : value });
                break;
            case 'deaNumberExpirationDate':
                errs.deaNumberExpirationDate = error;
                if (error === '') {
                    error = validations.validateExpirationDate(value);
                    if (error === '' && provider.deaNumberEffectiveDate) {
                        error = validations.validateDateRange(provider.deaNumberEffectiveDate, value);
                    }
                    errs.deaNumberExpirationDate = error;
                }
                setProvider({ ...provider, [name]: value === "" ? null : value });
                break;
            default:
                break;
        }
        setErrors(errs);
    }

    const isValidProvider = (p) => {
        let title = titleOrDegreeList ? titleOrDegreeList.items.find((s) => { return s.name === p.titleOrDegree }) : null;
        let supervisorInfoRequired = title ? title.supervisorInfoRequired : false;
        let deaNumberDatesRequired = p.deaNumber ? true : false;

        return !(!isValidExpirationDates(p) ||
            !p.firstName || !p.lastName || !p.npi || !p.ssn
            || !p.licenseNumber || !p.licenseNumberEffectiveDate || !p.licenseNumberExpirationDate
            || (deaNumberDatesRequired && (!p.deaNumberEffectiveDate || !p.deaNumberExpirationDate))
            || !p.birthDate || !p.gender || !p.titleOrDegree
            || !p.providerPrimarySpecialty || !p.primaryTaxonomy || !p.primarySpecialtyBoardCertificate || (p.isPcp == null)
            || (p.isHospitalBased == null)
            || !p.nonCaqhAttachments?.irsW9
            || (!p.caqhProviderId && !checkObject(p.nonCaqhAttachments, ["deaRegistration"])) || (p.hasAdmittingPrivileges == null)
            || (p.hasAdmittingPrivileges && p.hospitalAffiliations?.length === 0) || !p.primaryLocation
            || (supervisorInfoRequired && !checkObject(p.primarySupervisingPhysician, []))
            || (!checkObject(p.malpracticeInsurance, ["otherCarrier"]))
        );
    }
    const isValidExpirationDates = (p) => {
        let license = (p.licenseNumberExpirationDate && validations.validateExpirationDate(p.licenseNumberExpirationDate) === '');
        let deaNumber = p.deaNumber ? (p.deaNumberExpirationDate && validations.validateExpirationDate(p.deaNumberExpirationDate) === '') : true;
        let malPractice = (p.malpracticeInsurance?.malpracticeExpirationDate && validations.validateExpirationDate(p.malpracticeInsurance?.malpracticeExpirationDate) === '');
        return (license && deaNumber && malPractice);
    }

    const invalidProviders = () => {
        if ((emptyObject(provider) && rows.length > 0) || formIsValid) {
            for (var i = 0; i < rows?.length; i++) {
                if (rows[i].validProvider === 'N' && rowIdBeingEdited !== rows[i].id) {
                    return rows[i].firstName + " " + rows[i].lastName;
                }
            }

        }
        else if (emptyObject(provider) && rows.length === 0) {
            return " - Empty provider list.";
        }
        else {
            let title = titleOrDegreeList ? titleOrDegreeList.items.find((s) => { return s.name === provider.titleOrDegree }) : null;
            let supervisorInfoRequired = title ? title.supervisorInfoRequired : false;
            let deaNumberDatesRequired = provider.deaNumber ? true : false;

            let providerRequiredFields = [{ key: 'First Name', value: provider.firstName }, { key: 'Last Name', value: provider.lastName },
            { key: 'NPI', value: provider.npi },
            { key: 'Social Security Number', value: provider.ssn },
            { key: 'License Number', value: provider.licenseNumber },
            { key: 'License Number Effective Date', value: provider.licenseNumberEffectiveDate },
            { key: 'License Number Expiration Date', value: provider.licenseNumberExpirationDate },
            { key: 'Title/Degree', value: provider.titleOrDegree },
            { key: 'Date of Birth', value: provider.birthDate },
            { key: 'Gender', value: provider.gender },
            { key: 'Primary Specialty', value: provider.providerPrimarySpecialty },
            { key: 'Primary Taxonomy', value: provider.primaryTaxonomy },
            { key: 'Specialty Board Certificate', value: provider.primarySpecialtyBoardCertificate },
            { key: 'Primary Care Provider', value: !(provider.isPcp == null) },
            { key: 'Hospital Based', value: !(provider.isHospitalBased == null) },
            { key: 'Admitting Privileges', value: !(provider.hasAdmittingPrivileges == null) },
            { key: 'Credentialing - W-9', value: provider.nonCaqhAttachments?.irsW9 },
            { key: 'Credentialing', value: (provider.caqhProviderId || checkObject(provider.nonCaqhAttachments, ["deaRegistration"])) },
            { key: 'Primary Location', value: provider.primaryLocation },
            { key: 'Provider Supervisor', value: ((!supervisorInfoRequired) || checkObject(provider.primarySupervisingPhysician, [])) },
            { key: 'Malpractice Insurance', value: checkObject(provider.malpracticeInsurance, ["otherCarrier"]) },
            ];

            if (deaNumberDatesRequired) {
                var deaDatesRequiredFields = [{ key: 'DEA Number Effective Date', value: provider.deaNumberEffectiveDate }, { key: 'DEA Number Expiration Date', value: provider.deaNumberExpirationDate }];
                providerRequiredFields.splice(6, 0, ...deaDatesRequiredFields); //adding DEA date fileds error at number 6 in the list to show erros in sequence when saving/submiting application.
            }

            let missingField = validations.validateRequiredFields(providerRequiredFields);
            if (missingField) {
                return ` on provider ${provider.firstName ? provider.firstName : ""} ${provider.lastName ? provider.lastName : ""} - ${missingField}`;
            }

        }
        return '';
    }

    const checkObject = (obj, optionalFields) => {
        let allFieldsAvailable = true;
        for (const property in obj) {
            if (!obj[property] && !optionalFields.includes(property) && typeof obj[property] !== "boolean") {
                allFieldsAvailable = false;
            }
        }
        return allFieldsAvailable;
    }
    const emptyObject = (obj) => {
        if (!obj)
            return true;

        for (const property in obj) {
            let val = obj[property];
            if (val) {
                if (typeof val === "object") {
                    if (!emptyObject(val)) {
                        return false;
                    }
                } else {
                    return false;
                }
            } else if (typeof val === "boolean") {
                return false;
            }
        }
        return true;
    }
    const validateDuplicateProvider = (npi) => {
        if (npi) {
            for (var i = 0; i < rows.length; i++) {
                if (rowIdBeingEdited !== rows[i].id && npi === rows[i].npi)
                    return 'Provider with given NPI already exists.';
            }
        }
        return '';
    }

    useEffect(() => {
        setErrors({ ...errors, [uploadOccuredAt]: documentApiError });
        if (["irsW9", "deaRegistration", "curriculumVitae", "credentialingApplication"].includes(uploadOccuredAt)) {
            setProvider({ ...provider, nonCaqhAttachments: { ...provider.nonCaqhAttachments, [uploadOccuredAt]: lastAttachment } });
        } else if (uploadOccuredAt === "malpracticePolicy") {
            setProvider({ ...provider, malpracticeInsurance: { ...provider.malpracticeInsurance, [uploadOccuredAt]: lastAttachment } });
        } else {
            setProvider({ ...provider, [uploadOccuredAt]: lastAttachment });
        }
        if (lastAttachment) {
            setCookie('isFormDataSaved', 'false', { path: '/', maxAge: 31536000 });
        }
        dispatch({ type: types.SET_DEFAULT_DOCUMENT_STATE });
    }, [documentIsFetching, documentApiError]);

    const getUpdatedApplication = () => {
        let initialList = providersList ?? [];
        if (!emptyObject(provider)) {
            let updatedRows = [...rows];
            provider.providerGUID = provider.providerGUID ? provider.providerGUID : uuid();

            let currentRow = {
                id: (rowIdBeingEdited ? rowIdBeingEdited : provider.providerGUID),
                lastName: provider.lastName,
                middleInitial: provider.middleInitial,
                firstName: provider.firstName,
                npi: provider.npi,
                ssn: provider.ssn,
                validProvider: isValidProvider(provider) ? 'Y' : 'N'
            };
            if (rowIdBeingEdited) {
                const targetIndex = updatedRows.findIndex(p => p.id === rowIdBeingEdited);
                initialList = initialList.filter(function (obj) {
                    return (obj.providerGUID !== rowIdBeingEdited);
                });
                updatedRows[targetIndex] = currentRow;
            } else {
                updatedRows.push(currentRow);
            }
            setRows(updatedRows);
            setRowIdBeingEdited(currentRow.id);
            initialList = [...initialList, provider];
            setProvidersList(initialList);
        }

        let updated = { ...professionalApplication, providers: initialList };
        return updated;
    }
    const onSaveClick = () => {
        setShowAlert("");
        //Intermittent Save
        if (emptyObject(provider) && !providerRemovedFromGrid) {
            setButtonAction("");
        }
        else if (validations.validateForm(errors)) {
            let c = getUpdatedApplication();
            setCookie('isFormDataSaved', 'true', { path: '/', maxAge: 31536000 });
            var message = "Updating professional contract application.";
            var methodName = "onSaveClick";
            var jsonResult = JSON.stringify(professionalApplication);
            logInfoWrite(message, methodName, jsonResult);
            dispatch({ type: types.UPDATE_PROFESSIONAL_APPLICATION_START, application: c, submit: false });
            dispatch({ type: types.STORE_APPLICATION, applicationBeingUpdated: c });
            setProviderRemovedFromGrid(false);
        }
        else {
            setButtonAction("");
        }
    }
    const onBackButtonClick = () => {
        history.push("/practiceContactInfo");
    }
    const onNextButtonClick = () => { //SUBMIT click
        let invalidProvider = invalidProviders();
        if (invalidProvider !== '') {
            alert("Please correct errors " + invalidProvider);
            return;
        }
        setModal({
            modalOpen: true,
            modalBody: 'Do you wish to proceed with application submission?',
            modalButtonText: 'Submit',
            modalButtonClick: modalSubmitApplication
        });
    }
    const onRemoveProviderClick = (row) => { //Remove provider click
        setModal({
            modalOpen: true,
            modalBody: 'Do you wish to remove this provider?',
            modalButtonText: 'Remove',
            modalButtonClick: () => { modalRemoveProvider(row) }
        });
    }
    const modalSubmitApplication = () => { //SUBMIT confirmed
        setButtonAction("submitClick");
        let c = getUpdatedApplication();
        var message = "Submitting professional contract application.";
        var methodName = "modalSubmitApplication";
        var jsonResult = JSON.stringify(c);
        logInfoWrite(message, methodName, jsonResult);
        setCookie('isFormDataSaved', 'true', { path: '/', maxAge: 31536000 });
        dispatch({ type: types.UPDATE_PROFESSIONAL_APPLICATION_START, application: c, submit: true });
        toggle();
    }
    const modalRemoveProvider = (row) => { //Removing Provider confirmed
        var message = "Removing provider from in row " + row.id + " from grid.";
        var methodName = "modalRemoveProvider";
        var jsonResult = JSON.stringify(providersList);
        logInfoWrite(message, methodName, jsonResult);
        if (rowIdBeingEdited === row.id) {
            setCurrentProvider(newProviderObj);
            setRowIdBeingEdited(null);
            setRequiredErrors(initialErrorObj);
            setRequiresPriv(false);
            setErrors(initialErrorObj);
            setCookie('isFormDataSaved', 'true', { path: '/', maxAge: 31536000 });
        }

        setRows(rows.filter(function (obj) {
            return obj.id !== row.id;
        }));

        var list = providersList.filter(function (obj) {
            return (obj.providerGUID !== row.id);
        });
        setProvidersList(list);
        let updated = { ...professionalApplication, providers: list };
        var message = "Updating professional contract application.";
        var methodName = "modalRemoveProvider";
        var jsonResult = JSON.stringify(updated);
        logInfoWrite(message, methodName, jsonResult);
        dispatch({ type: types.UPDATE_PROFESSIONAL_APPLICATION_START, application: updated, submit: false });
        dispatch({ type: types.STORE_APPLICATION, applicationBeingUpdated: updated });
        setProviderRemovedFromGrid(true);
        toggle();
    }
    const onSaveNewProviderClick = () => {
        setButtonAction("saveAndNewClick");
        setIsRequiredDEADates(false);
        setShowRequiredFieldErrorInitially(false);
        onSaveClick();
    }

    const selectOrRemoveProvider = (e) => {
        let message = "";
        let methodName = "selectOrRemoveProvider";
        let jsonResult = "";

        if (e.field === "removeProvider") {
            message = "Removing row " + e.row.id + " from providers grid.";
            jsonResult = JSON.stringify(providersList);
            logInfoWrite(message, methodName, jsonResult);
            onRemoveProviderClick(e.row);
        }
        else if (e.field === "editProvider") {
            let id = e.row.id;
            setRowIdBeingEdited(id);
            var p = providersList.find(function (obj) {
                return (obj.providerGUID === id);
            });
            //this is necessary because dates are saved in teh DB as DateTimes, but we are only requiring Dates on the front end
            setCurrentProvider({
                ...p,
                birthDate: p.birthDate?.substring(0, 10),
                licenseNumberEffectiveDate: p.licenseNumberEffectiveDate?.substring(0, 10),
                licenseNumberExpirationDate: p.licenseNumberExpirationDate?.substring(0, 10),
                deaNumberEffectiveDate: p.deaNumberEffectiveDate?.substring(0, 10),
                deaNumberExpirationDate: p.deaNumberExpirationDate?.substring(0, 10),
                malpracticeInsurance: {
                    ...p.malpracticeInsurance,
                    malpracticeEffectiveDate: p.malpracticeInsurance?.malpracticeEffectiveDate?.substring(0, 10),
                    malpracticeExpirationDate: p.malpracticeInsurance?.malpracticeExpirationDate?.substring(0, 10)
                }
            });
            if (p.providerPrimarySpecialty && p.providerPrimarySpecialty.requiresPriv) {
                setRequiresPriv(true);
            }
            message = "Editing row for provider " + p.npi + " in providers grid.";
            jsonResult = JSON.stringify(p);
            logInfoWrite(message, methodName, jsonResult);
            setShowRequiredFieldErrorInitially(true);
            let requiredErrorString = (testValue, label) => {
                let err = testValue ? '' : `${label} is required`;
                if (err === '' && (label === "License Number Expiration Date" || label === "DEA Number Expiration Date"
                    || label === "Malpractice Expiration Date")) {
                    err = validations.validateExpirationDate(testValue);
                }
                return err;
            }

            if (p.deaNumber) {
                setIsRequiredDEADates(true);
            }
            else {
                setIsRequiredDEADates(false);
            }
            setRequiredErrors({
                ...initialErrorObj,
                firstName: requiredErrorString(p.firstName, "First Name"),
                lastName: requiredErrorString(p.lastName, "Last Name"),
                npi: requiredErrorString(p.npi, "NPI"),
                ssn: requiredErrorString(p.ssn, "Social Security Number"),
                licenseNumber: requiredErrorString(p.licenseNumber, "License Number"),
                licenseNumberEffectiveDate: requiredErrorString(p.licenseNumberEffectiveDate, "License Number Effective Date"),
                licenseNumberExpirationDate: requiredErrorString(p.licenseNumberExpirationDate, "License Number Expiration Date"),
                deaNumberEffectiveDate: p.deaNumber ? requiredErrorString(p.deaNumberEffectiveDate, "DEA Number Effective Date") : '',
                deaNumberExpirationDate: p.deaNumber ? requiredErrorString(p.deaNumberExpirationDate, "DEA Number Expiration Date") : '',
                titleOrDegree: requiredErrorString(p.titleOrDegree, "Title/Degree"),
                gender: requiredErrorString(p.gender, "Gender"),
                providerPrimarySpecialty: requiredErrorString(p.providerPrimarySpecialty, "Primary Specialty"),
                primaryTaxonomy: requiredErrorString(p.primaryTaxonomy, "Primary Taxonomy"),
                isPcp: requiredErrorString(p.isPcp, "Primary Care Provider"),
                isHospitalBased: requiredErrorString(p.isHospitalBased, "Hospital Based"),

                hasAdmittingPrivileges: requiredErrorString(p.hasAdmittingPrivileges, "Admitting Privileges"),
                primaryLocation: requiredErrorString(p.primaryLocation, "primaryLocation"),
                malpracticeInsuranceTypes: requiredErrorString(p.malpracticeInsurance.type, "Malpratice Insurance Type"),
                malpracticeInsuranceCarriers: requiredErrorString(p.malpracticeInsurance.carrier, "Malpratice Insurance Carrier"),
                malpracticeInsuranceAmounts: requiredErrorString(p.malpracticeInsurance.amounts, "Malpratice Insurance Amounts"),
                birthDate: requiredErrorString(p.birthDate, "Date of Birth"),
                malpracticeEffectiveDate: requiredErrorString(p.malpracticeInsurance.malpracticeEffectiveDate, "Malpractice Effective Date"),
                malpracticeExpirationDate: requiredErrorString(p.malpracticeInsurance.malpracticeExpirationDate, "Malpractice Expiration Date"),
            });
            setErrors(initialErrorObj);
        }
    }
    const columns = [
        {
            field: 'validProvider',
            headerName: 'Data Complete',
            flex: 1,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            renderCell: (params) => {
                if (params.row.validProvider === 'N') {
                    return (<FontAwesomeIcon icon="times-circle" color="red" />)
                } else {
                    return (<FontAwesomeIcon icon="check-circle" color="green" />)
                }
            },
            align: 'center',
        },
        {
            field: 'firstName',
            headerName: 'First Name',
            flex: 1,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center',
        },
        {
            field: 'middleInitial',
            headerName: 'Middle Initial',
            flex: 1,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center',
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            flex: 1,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center',
        },
        {
            field: 'npi',
            headerName: 'NPI',
            type: 'number',
            flex: 1,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center',
        },
        {
            field: 'ssn',
            headerName: 'Social Security #',
            type: 'number',
            flex: 1,
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            align: 'center',
        },
        {
            field: 'editProvider',
            headerName: 'Edit',
            headerClassName: 'grid-header',
            headerAlign: 'center',
            cellClassName: 'grid-rows',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 120,
            disableClickEventBubbling: true,
            renderCell: () => (
                <strong> <Button className="btn btn-primary" size="small" > Edit </Button> </strong>
            ),
            align: 'center'
        },
        {
            field: 'removeProvider',
            headerName: 'Remove',
            headerClassName: 'grid-header',
            cellClassName: 'grid-rows',
            description: 'This column has a value getter and is not sortable.',
            sortable: false,
            width: 160,
            disableClickEventBubbling: true,
            renderCell: () => (
                <strong> <Button className="btn btn-primary" size="small" > Remove </Button> </strong>
            ),
        },
    ];

    const logInfoWrite = (message, methodName, jsonResult) => {
        let loggingModel = {
            message: message,
            pageName: "ProviderInformation",
            methodName: methodName,
            jsonResult: jsonResult
        };

        setClientLoggingModel(loggingModel);
        if (clientLoggingModel.methodName !== null) {
            dispatch({ type: types.LOG_INFO_START, clientLoggingModel, userInfo });
        }
    }

    return <div className="provider-information">
        {showAlert.length > 0 && <CustomAlert type={showAlert} />}
        <SectionHeader>Provider Information</SectionHeader>
        {
            pageLoading ?
                <LoadingAnimation /> :
                <div>
                    <Form autoComplete="off">
                        <Row>
                            <Col md={12}>
                                <FormGroup>
                                    <div md={12} style={{ height: 400 }}>
                                        <DataGrid
                                            rows={rows}
                                            columns={columns}
                                            pageSize={5}
                                            onCellClick={(params, event) => {
                                                event.preventDefault();
                                                selectOrRemoveProvider(params);
                                            }}
                                            components={{
                                                NoRowsOverlay: () => (
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center"
                                                        }}
                                                    >
                                                        Data will automatically be entered here after you enter provider information
                                                    </div>
                                                )
                                            }}
                                        />
                                    </div>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row></Row>

                        <SubSectionHeader>Provider Identification</SubSectionHeader>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label > <span className="required-field"></span> First Name</Label>
                                    <Input className={(errors.firstName.length > 0 ? "input-error" : "")} type="text" name="firstName" id="firstName" value={provider.firstName || ""} onChange={saveInputs} autoComplete="off" onBlur={onFocusOut} />
                                    {errors.firstName.length > 0 ? <span className='error'>{errors.firstName}</span> : ""}
                                    <span className='error'>{requiredErrors.firstName}</span>
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormGroup>
                                    <Label className=""> Middle Initial</Label>
                                    <Input className={(errors.middleInitial.length > 0 ? "input-error" : "")} type="text" name="middleInitial" id="middleInitial" value={provider.middleInitial || ""} onChange={saveInputs} autoComplete="off" onBlur={onFocusOut} />
                                    {errors.middleInitial.length > 0 ? <span className='error'>{errors.middleInitial}</span> : ""}
                                </FormGroup>
                            </Col>
                            <Col md={4}>
                                <FormGroup>
                                    <Label ><span className="required-field"></span>Last Name</Label>
                                    <Input className={(errors.lastName.length > 0 ? "input-error" : "")} type="text" name="lastName" id="lastName" value={provider.lastName || ""} onChange={saveInputs} autoComplete="off" onBlur={onFocusOut} />
                                    {errors.lastName.length > 0 ? <span className='error'>{errors.lastName}</span> : ""}
                                    <span className='error'>{requiredErrors.lastName}</span>
                                </FormGroup>
                            </Col>
                            <Col md={2}>
                                <FormField type="select"
                                    name="suffix"
                                    id="suffix"
                                    label="Suffix"
                                    options={suf.suffix}
                                    onChange={saveInputs}
                                    value={provider.suffix || ""}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label > <span className="required-field"></span> NPI</Label>
                                    <Input className={(errors.npi.length > 0 ? "input-error" : "")} type="text" name="npi" id="npi" value={provider.npi || ""} onChange={saveInputs} autoComplete="off" />
                                    {errors.npi.length > 0 ? <span className='error'>{errors.npi}</span> : ""}
                                    {errors.duplicateProvider.length > 0 && <Row><Col><span className='error'>{errors.duplicateProvider}</span></Col></Row>}
                                    <span className='error'>{requiredErrors.npi}</span>
                                    {!requiredErrors.npi && showRequiredFieldErrorInitially && !provider.npi && <span className='error'> NPI is required</span>}
                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label ><span className="required-field"></span> Social Security Number</Label>
                                    <InputMask mask="999-99-9999" maskChar={null} name="ssn" id="ssn"
                                        value={provider.ssn || ""} errors="errors.pcPhone" onChange={saveInputs}
                                        autoComplete="off" placeholder="XXX-XX-XXXX" className="phoneInputMask, form-control" />
                                    {errors.ssn.length > 0 ? <span className='error'>{errors.ssn}</span> : ""}
                                    <span className='error'>{requiredErrors.ssn}</span>
                                    {!requiredErrors.ssn && showRequiredFieldErrorInitially && !provider.ssn && <span className='error'> Social Security Number is required</span>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <Label >Medicaid Number</Label>
                                    <Input type="text" className={(errors.medicaidId.length > 0 ? "input-error" : "")} name="medicaidId"
                                        id="medicaidId" value={provider.medicaidId || ""} onChange={saveInputs} autoComplete="off" maxLength={9} />
                                    {errors.medicaidId.length > 0 ? <span className='error'>{errors.medicaidId}</span> : ""}

                                </FormGroup>
                            </Col>
                            <Col md={6}>
                                <FormGroup>
                                    <Label >Medicare Number</Label>
                                    <Input className={(errors.medicareId.length > 0 ? "input-error" : "")} type="text" name="medicareId" id="medicareId" maxLength={20} value={provider.medicareId || ""} onChange={saveInputs} autoComplete="off" />
                                    {errors.medicareId.length > 0 ? <span className='error'>{errors.medicareId}</span> : ""}

                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <FormField type="text"
                                    required
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially}
                                    name="licenseNumber"
                                    id="licenseNumber"
                                    label="License Number"
                                    value={provider.licenseNumber || ""}
                                    onChange={saveInputs}
                                    error={errors.licenseNumber}
                                />
                            </Col>
                            <Col md={4}>
                                <FormField type="date"
                                    required
                                    max="9999-12-31"
                                    name="licenseNumberEffectiveDate"
                                    id="licenseNumber-effective-date"
                                    label="License Number Effective Date"
                                    value={provider.licenseNumberEffectiveDate ? provider.licenseNumberEffectiveDate : ""}
                                    onChange={(e) => { saveEffectiveDate(e.target.name, e.target.value, "License Number Effective Date") }}
                                    error={errors.licenseNumberEffectiveDate.length > 0 ? errors.licenseNumberEffectiveDate : requiredErrors.licenseNumberEffectiveDate}
                                />
                            </Col>
                            <Col md={4}>
                                <FormField type="date"
                                    required
                                    max="9999-12-31"
                                    name="licenseNumberExpirationDate"
                                    id="license-expiration-date"
                                    label="License Number Expiration Date"
                                    value={provider.licenseNumberExpirationDate ? provider.licenseNumberExpirationDate : ""}
                                    onChange={(e) => { saveExpirationDate(e.target.name, e.target.value, "License Number Expiration Date") }}
                                    error={errors.licenseNumberExpirationDate.length > 0 ? errors.licenseNumberExpirationDate : requiredErrors.licenseNumberExpirationDate}
                                />
                            </Col>
                        </Row> <Row>
                            <Col md={4}>
                                <FormField type="text"
                                    name="deaNumber"
                                    id="deaNumber"
                                    label="DEA Number"
                                    value={provider.deaNumber || ""}
                                    onChange={saveInputs}
                                    error={errors.deaNumber}
                                />
                            </Col>
                            <Col md={4}>
                                <FormField type="date"
                                    required={isRequiredDEADates}
                                    disabled={!isRequiredDEADates}
                                    max="9999-12-31"
                                    name="deaNumberEffectiveDate"
                                    id="deaNumber-effective-date"
                                    label="DEA Number Effective Date"
                                    value={provider.deaNumberEffectiveDate ? provider.deaNumberEffectiveDate : ""}
                                    onChange={(e) => { saveEffectiveDate(e.target.name, e.target.value, "DEA Number Effective Date") }}
                                    error={errors.deaNumberEffectiveDate.length > 0 ? errors.deaNumberEffectiveDate : requiredErrors.deaNumberEffectiveDate}
                                />
                            </Col>
                            <Col md={4}>
                                <FormField type="date"
                                    required={isRequiredDEADates}
                                    disabled={!isRequiredDEADates}
                                    max="9999-12-31"
                                    name="deaNumberExpirationDate"
                                    id="deaNumber-expiration-date"
                                    label="DEA Number Expiration Date"
                                    value={provider.deaNumberExpirationDate ? provider.deaNumberExpirationDate : ""}
                                    onChange={(e) => { saveExpirationDate(e.target.name, e.target.value, "DEA Number Expiration Date") }}
                                    error={errors.deaNumberExpirationDate.length > 0 ? errors.deaNumberExpirationDate : requiredErrors.deaNumberExpirationDate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>

                                <Label className="required-field">Title/Degree</Label>
                                <Typeahead
                                    id="titleDegree-typeahead"
                                    labelKey="label"
                                    options={titleOrDegreeOptions}
                                    selected={TitleDegreeTypeahead}
                                    onChange={onTitleDegreeChange}
                                    placeholder="Select"
                                    onBlur={() => onTypeaheadBlur(titleOrDegreeInputRef, titleOrDegreeOptions, "TitleDegree", false)}
                                    ref={titleOrDegreeInputRef}
                                />
                                {requiredErrors.titleOrDegree ? <span className='error'>{requiredErrors.titleOrDegree}</span> : ""}
                            </Col>
                            <Col md={6}>
                                <Label>Languages Spoken other than English</Label>
                                <Typeahead
                                    id="language-typeahead"
                                    labelKey="label"
                                    multiple
                                    onChange={onLanguageTypeaheadChange}
                                    options={languageList}
                                    placeholder="Select"
                                    selected={LanguageTypeahead}
                                    onBlur={() => onTypeaheadBlur(languageInputRef, languageList, "Language", true)}
                                    ref={languageInputRef}
                                />

                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormGroup>
                                    <FormField type="date"
                                        required
                                        showRequiredErrorOnLoad={showRequiredFieldErrorInitially}
                                        max={new Date().toISOString().split("T")[0]}
                                        name="birth-date"
                                        id="birth-date"
                                        label="DOB (Date of Birth)"
                                        value={provider.birthDate ? provider.birthDate : ""}
                                        onChange={(e) => { saveBirthDate(e.target.value) }}
                                        error={errors.birthDate.length > 0 ? errors.birthDate : requiredErrors.birthDate}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md={5}>
                                <FormGroup>
                                    <Label ><span className="required-field"></span> Gender</Label>
                                    <Row className="margin-left gender-radio">
                                        <span><Input type="radio" id="M" name="gender" onChange={saveInputs} checked={provider.gender === 'M' ? true : false} />Male</span>
                                        <span><Input type="radio" id="F" name="gender" onChange={saveInputs} checked={provider.gender === 'F' ? true : false} />Female</span>
                                    </Row>
                                    {showRequiredFieldErrorInitially && !provider.gender && <span className='error'> Gender is required</span>}
                                </FormGroup>
                            </Col>
                        </Row>

                        <SubSectionHeader>Provider Specialty</SubSectionHeader>
                        <Row>
                            <Col md={6}>
                                <FormField type="yes/no"
                                    required
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially}
                                    name="isPcp"
                                    id="isPcp"
                                    label="Are you a Primary Care Provider?"
                                    onChange={saveInputs}
                                    value={provider.isPcp}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Label className="required-field">Primary Specialty</Label>
                                <Typeahead
                                    id="providerPrimarySpecialty-typeahead"
                                    labelKey="label"
                                    options={specialtiesListOptions}
                                    selected={ProviderPrimarySpecialtyTypeahead}
                                    onChange={onProviderPrimarySpecialtyChange}
                                    placeholder="Select"
                                    onBlur={() => onTypeaheadBlur(providerPrimarySpecialtyInputRef, specialtiesListOptions, "ProviderPrimarySpecialty", false)}
                                    ref={providerPrimarySpecialtyInputRef}
                                />
                                {requiredErrors.providerPrimarySpecialty ? <span className='error'>{requiredErrors.providerPrimarySpecialty}</span> : errors.providerPrimarySpecialty ?
                                    <div className="error">
                                        {errors.providerPrimarySpecialty}
                                    </div>
                                    :
                                    ""}
                            </Col>
                            <Col md={4}>
                                <Label className="required-field">Primary Taxonomy</Label>
                                <Typeahead
                                    id="primaryTaxonomy-typeahead"
                                    labelKey="label"
                                    options={taxonomiesListOptions}
                                    selected={primaryTaxonomyTypeahead}
                                    onChange={onPrimaryTaxonomyChange}
                                    placeholder="Select"
                                    onBlur={() => onTypeaheadBlur(primaryTaxonomyInputRef, taxonomiesListOptions, "PrimaryTaxonomy", false)}
                                    ref={primaryTaxonomyInputRef}
                                />
                                {requiredErrors.primaryTaxonomy ? <span className='error'>{requiredErrors.primaryTaxonomy}</span> : errors.primaryTaxonomy ?
                                    <div className="error">
                                        {errors.primaryTaxonomy}
                                    </div>
                                    :
                                    ""}
                            </Col>
                            <Col md={4}>
                                <FormField type="select"
                                    required
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially}
                                    name="primarySpecialtyBoardCertificate"
                                    id="primarySpecialtyBoardCertificate"
                                    label="Primary Specialty Board Certification"
                                    options={boardCertification.ProviderSpecialtyBoardCertification}
                                    onChange={saveInputs}
                                    value={provider.primarySpecialtyBoardCertificate || ""}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                {requiresPriv ? <div className="info" ><span><FontAwesomeIcon icon="info-circle" color="orange" />Your specialty requires Affiliation(s) at an in-network facility</span></div> : ""}
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <Label>Secondary Specialty</Label>
                                <Typeahead
                                    id="providerSecondarySpecialty-typeahead"
                                    labelKey="label"
                                    options={specialtiesListOptions}
                                    selected={ProviderSecondarySpecialtyTypeahead}
                                    onChange={onProviderSecondarySpecialtyChange}
                                    placeholder="Select"
                                    onBlur={() => onTypeaheadBlur(providerSecondarySpecialtyInputRef, specialtiesListOptions, "ProviderSecondarySpecialty", false)}
                                    ref={providerSecondarySpecialtyInputRef}
                                />
                                {errors.providerSecondarySpecialty && (
                                    <div className="error">
                                        {errors.providerSecondarySpecialty}
                                    </div>
                                )}
                            </Col>
                            <Col md={4}>
                                <Label>Secondary Taxonomy</Label>
                                <Typeahead
                                    id="secondaryTaxonomy-typeahead"
                                    labelKey="label"
                                    options={taxonomiesListOptions}
                                    selected={secondaryTaxonomyTypeahead}
                                    onChange={onSecondaryTaxonomyChange}
                                    placeholder="Select"
                                    onBlur={() => onTypeaheadBlur(secondaryTaxonomyInputRef, taxonomiesListOptions, "SecondaryTaxonomy", false)}
                                    ref={secondaryTaxonomyInputRef}
                                />
                                {errors.secondaryTaxonomy && (
                                    <div className="error">
                                        {errors.secondaryTaxonomy}
                                    </div>
                                )}
                            </Col>
                            <Col md={4}>
                                <FormField type="select"
                                    name="secondarySpecialtyBoardCertificate"
                                    id="secondarySpecialtyBoardCertificate"
                                    label="Secondary Specialty Board Certification Type"
                                    options={boardCertification.ProviderSpecialtyBoardCertification}
                                    onChange={saveInputs}
                                    value={provider.secondarySpecialtyBoardCertificate || ""}
                                />
                            </Col>
                        </Row>

                        <SubSectionHeader>Provider Accreditation/Licensing</SubSectionHeader>
                        <Row>
                            <Col md={6}>
                                <FileUpload type="file"
                                    id="boardCertification"
                                    name="boardCertification"
                                    label="Board Certification"
                                    applicationId={professionalApplication?.id}
                                    fileUploaded={provider.boardCertification}
                                    error={errors.boardCertification}
                                />
                            </Col>
                            <Col md={6}>
                                <FileUpload type="file"
                                    id="currentRadiologyCertificate"
                                    name="currentRadiologyCertificate"
                                    label="Current Radiology Certificate"
                                    applicationId={professionalApplication?.id}
                                    fileUploaded={provider.currentRadiologyCertificate}
                                    error={errors.currentRadiologyCertificate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FileUpload type="file"
                                    id="currentStateLicense"
                                    name="currentStateLicense"
                                    label="Current State License"
                                    applicationId={professionalApplication?.id}
                                    fileUploaded={provider.currentStateLicense}
                                    error={errors.currentStateLicense}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormField type="yes/no"
                                    required
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially}
                                    name="isHospitalBased"
                                    id="isHospitalBased"
                                    label="Hospital-Based?"
                                    onChange={saveInputs}
                                    value={provider.isHospitalBased}
                                />
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={12}>
                                <div>
                                    <Label>BSWHP requires that any facility used be a part of the network as well. Below is the list of all facilities that are in network. Please select any facility in which you have affiliations.
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FormField type="yes/no"
                                    required
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially}
                                    name="hasAdmittingPrivileges"
                                    id="hasAdmittingPrivileges"
                                    label="Do you have admitting privileges?"
                                    onChange={saveInputs}
                                    error={errors.facilityAffiliations}
                                    value={provider.hasAdmittingPrivileges}
                                />
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <Label class={provider.hasAdmittingPrivileges ? "required-field" : "" }>Facility Affiliations</Label>
                                <Typeahead
                                    id="facilityAffiliations-typeahead"
                                    labelKey="label"
                                    multiple
                                    onChange={onFacilityAffiliationsTypeaheadChange}
                                    options={providerFacilityAffiliationList}
                                    placeholder="Select"
                                    selected={FacilityAffiliationsTypeahead}
                                    onBlur={() => onTypeaheadBlur(facilityAffiliationsInputRef, providerFacilityAffiliationList, "FacilityAffiliations", true)}
                                    ref={facilityAffiliationsInputRef}
                                />
                                {provider.hasAdmittingPrivileges && requiredErrors.facilityAffiliations ? <span className='error'>{requiredErrors.facilityAffiliations}</span> : ""}
                            </Col>
                        </Row>

                        <SubSectionHeader>Provider Credentialing</SubSectionHeader>
                        <Row>
                            <Col md={12}>
                                <div>
                                    <Label>If the provider has a CAQH ID, please enter it below. If there is no CAQH ID, the attachments indicated with an asterisk ( <span className='required-field'> )</span> below are required.
                                    </Label>
                                </div>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={3}>
                                <FormGroup>
                                    <Label for="caqhProviderId">CAQH Number</Label>
                                    <Input className={(errors.caqhProviderId.length > 0 ? "input-error" : "")} type="text" name="caqhProviderId" id="caqhProviderId" value={provider.caqhProviderId || ""} onChange={saveInputs} />
                                    {errors.caqhProviderId.length > 0 &&
                                        <span className='error'>{errors.caqhProviderId}</span>}
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FileUpload type="file"
                                    required
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially}
                                    id="irsW9"
                                    name="irsW9"
                                    label="W-9"
                                    applicationId={professionalApplication?.id}
                                    fileUploaded={provider.nonCaqhAttachments?.irsW9}
                                    error={errors.irsW9}
                                />
                            </Col>
                            <Col md={6}>
                                <FileUpload type="file"
                                    required={false}
                                    showRequiredErrorOnLoad={false}
                                    id="deaRegistration"
                                    name="deaRegistration"
                                    label="Current DEA Registration"
                                    applicationId={professionalApplication?.id}
                                    fileUploaded={provider.nonCaqhAttachments?.deaRegistration}
                                    error={errors.deaRegistration}
                                />
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <FileUpload type="file"
                                    required={(!provider.caqhProviderId ? true : false)}
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially && !provider.caqhProviderId}
                                    id="curriculumVitae"
                                    name="curriculumVitae"
                                    label="Curriculum Vitae"
                                    applicationId={professionalApplication?.id}
                                    fileUploaded={provider.nonCaqhAttachments?.curriculumVitae}
                                    error={errors.curriculumVitae}
                                />
                            </Col>
                            <Col md={6}>
                                <FileUpload type="file"
                                    required={(!provider.caqhProviderId ? true : false)}
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially && !provider.caqhProviderId}
                                    id="credentialingApplication"
                                    name="credentialingApplication"
                                    label="Completed Texas Standardized Credentialing Application"
                                    applicationId={professionalApplication?.id}
                                    fileUploaded={provider.nonCaqhAttachments?.credentialingApplication}
                                    error={errors.credentialingApplication}
                                />
                                <div>
                                    <p><a target="_blank" rel="noreferrer" href="https://www.tdi.texas.gov/forms/finmcqa/lhl234.pdf">Click here </a>to download TSCA form</p>
                                </div>
                            </Col>
                        </Row>

                        <SubSectionHeader>Provider Locations</SubSectionHeader>
                        <Row form>
                            <Col md={6}>
                                <FormField type="select"
                                    required
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially}
                                    name="primaryLocation"
                                    id="primaryLocation"
                                    label="Select PRIMARY location where services are provided"
                                    error={errors.primaryLocation}
                                    options={locations}
                                    onChange={saveInputs}
                                    value={provider.primaryLocation || ""}
                                />
                            </Col>
                            <Col md={6}>
                                <FormField type="Dropdown"
                                    name="additionalLocations"
                                    label="Select ADDITIONAL locations where services are provided"
                                    error={errors.additionalLocations}
                                    onChange={saveInputs}
                                    value={additionalLocations}
                                    datasource={locations}
                                    selectedOptions={provider.additionalLocations}
                                />
                            </Col>
                        </Row>

                        <SubSectionHeader>Provider Supervisor</SubSectionHeader>
                        <Row>
                            <Col md={12}>
                                <FormField type="yes/no"
                                    required={isTitleOrDegreeActive}
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially && isTitleOrDegreeActive}
                                    name="midlevelProtocolsAvailableOnRequest"
                                    id="midlevelProtocolsAvailableOnRequest"
                                    label="Are you a midlevel provider?"
                                    onChange={saveInputs}
                                    value={provider.primarySupervisingPhysician?.midlevelProtocolsAvailableOnRequest}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormField type="text"
                                    required={isTitleOrDegreeActive}
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially && isTitleOrDegreeActive}
                                    name="physicianName"
                                    id="physicianName"
                                    label="Supervising Physician name (Midlevels Only)"
                                    error={errors.physicianName}
                                    onChange={saveInputs}
                                    onFocusOut={(e) => onFocusOut(e)}
                                    maxLength={20}
                                    value={provider.primarySupervisingPhysician?.name || ""}
                                />
                            </Col>
                            <Col md={6}>
                                <FormField type="text"
                                    required={isTitleOrDegreeActive}
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially && isTitleOrDegreeActive}
                                    name="physicianNpi"
                                    id="physicianNpi"
                                    label="Supervising Physician's NPI"
                                    error={errors.physicianNpi}
                                    onChange={saveInputs}
                                    value={provider.primarySupervisingPhysician?.npi || ""}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <Label className={isTitleOrDegreeActive ? "required-field" : ""}>Supervising Physician's Specialty</Label>
                                <Typeahead
                                    id="physicianSpecialty-typeahead"
                                    labelKey="label"
                                    options={specialtiesListOptions}
                                    selected={PhysicianSpecialtyTypeahead}
                                    onChange={onPhysicianSpecialtyChange}
                                    placeholder="Select"
                                    onBlur={() => onTypeaheadBlur(physicianSpecialtyInputRef, specialtiesListOptions, "PhysicianSpecialty", false)}
                                    ref={physicianSpecialtyInputRef}
                                />
                                {isTitleOrDegreeActive && requiredErrors.physicianSpecialty ? <span className='error'>{requiredErrors.physicianSpecialty}</span> : ""}
                            </Col>
                            <Col md={6}>
                                <FormField type="yes/no"
                                    required={isTitleOrDegreeActive}
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially && isTitleOrDegreeActive}
                                    name="isPhysicianContracted"
                                    id="isPhysicianContracted"
                                    label="Is Supervising Physician contracted?"
                                    onChange={saveInputs}
                                    value={provider.primarySupervisingPhysician?.isPhysicianContracted}
                                />
                            </Col>
                        </Row>

                        <SubSectionHeader>Provider Insurance</SubSectionHeader>
                        <Row>
                            <Col md={4}>
                                <FormField type="select"
                                    required
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially}
                                    name="type"
                                    id="type"
                                    label="Malpractice Insurance Type"
                                    error={errors.type}
                                    options={malpracticeInsuranceTypesOptions}
                                    onChange={saveInputs}
                                    value={provider.malpracticeInsurance?.type?.name || ""}
                                />
                            </Col>
                            <Col md={4}>
                                <FormField type="date"
                                    required
                                    max="9999-12-31"
                                    name="malpracticeEffectiveDate"
                                    id="malpractice-effective-date"
                                    label="Malpractice Insurance Effective Date"
                                    value={provider.malpracticeInsurance?.malpracticeEffectiveDate ? provider.malpracticeInsurance?.malpracticeEffectiveDate : ""}
                                    onChange={(e) => { saveEffectiveDate(e.target.name, e.target.value, "Malpractice Insurance Effective Date") }}
                                    error={errors.malpracticeEffectiveDate.length > 0 ? errors.malpracticeEffectiveDate : requiredErrors.malpracticeEffectiveDate}
                                />
                            </Col>
                            <Col md={4}>
                                <FormField type="date"
                                    required
                                    max="9999-12-31"
                                    name="malpracticeExpirationDate"
                                    id="malpractice-expiration-date"
                                    label="Malpractice Insurance Expiration Date"
                                    value={provider.malpracticeInsurance?.malpracticeExpirationDate ? provider.malpracticeInsurance?.malpracticeExpirationDate : ""}
                                    onChange={(e) => { saveExpirationDate(e.target.name, e.target.value, "Malpractice Insurance Expiration Date") }}
                                    error={errors.malpracticeExpirationDate.length > 0 ? errors.malpracticeExpirationDate : requiredErrors.malpracticeExpirationDate}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FileUpload type="file"
                                    required
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially}
                                    id="malpracticePolicy"
                                    name="malpracticePolicy"
                                    label="Current Malpractice Policy"
                                    applicationId={professionalApplication?.id}
                                    fileUploaded={provider.malpracticeInsurance?.malpracticePolicy}
                                    error={errors.malpracticePolicy}
                                />
                            </Col>
                            <Col md={6}>
                                <Label className="required-field">Malpractice Insurance Carrier</Label>
                                <Typeahead
                                    id="carrier-typeahead"
                                    labelKey="label"
                                    options={malpracticeInsuranceCarriersOptions}
                                    selected={CarrierTypeahead}
                                    onChange={onCarrierChange}
                                    placeholder="Select"
                                    onBlur={() => onTypeaheadBlur(carrierInputRef, malpracticeInsuranceCarriersOptions, "Carrier", false)}
                                    ref={carrierInputRef}
                                />
                                {requiredErrors.carrier ? <span className='error'>{requiredErrors.carrier}</span> : ""}

                            </Col>
                        </Row>
                        <Row>
                            <Col md={6}>
                                <FormField type="select"
                                    required
                                    showRequiredErrorOnLoad={showRequiredFieldErrorInitially}
                                    name="amounts"
                                    id="amounts"
                                    label="Malpractice Insurance Aggregate and Per Occurrence Amounts"
                                    error={errors.amounts}
                                    options={amountsOptions}
                                    onChange={saveInputs}
                                    value={provider.malpracticeInsurance?.amounts || ""}
                                />
                            </Col>
                            <Col md={6}>
                                {provider.malpracticeInsurance?.carrier?.name === 'Other' &&
                                    < FormField type="text"
                                        name="otherCarrier"
                                        id="otherCarrier"
                                        label="Other Malpractice Insurance Carrier"
                                        error={errors.otherCarrier}
                                        onChange={saveInputs}
                                        value={provider.malpracticeInsurance?.otherCarrier || ""}
                                    />
                                }
                            </Col>
                        </Row>

                        <Row></Row>
                        <Row>
                            <Col md={4}></Col>
                            <Col md={4}>
                                <Button className="btn btn-primary" onClick={onSaveNewProviderClick}>SAVE AND ADD NEW PROVIDER</Button>
                            </Col>
                            <Col md={4}></Col>
                        </Row>
                    </Form>
                    <Modal isOpen={modal.modalOpen} toggle={toggle} >
                        <ModalBody>
                            {modal.modalBody}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={modal.modalButtonClick}>{modal.modalButtonText}</Button>{' '}
                            {modal.modalHideCancel ? "" : <Button color="secondary" onClick={toggle}>Cancel</Button>}
                        </ModalFooter>
                    </Modal>

                    <NavigationFooter onNextPageClick={onNextButtonClick}
                        onSaveClick={onSaveClick}
                        onBackButtonClick={onBackButtonClick}
                        isValidData={() => validations.validateForm(errors)}
                        requiredFields={requiredFields}
                        submitIsDisplayed={true} />
                </div>
        }
    </div>;
}