import React, { useState, useEffect, useRef } from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import { validateForNotNullOrWhitespace, validateCityName, validateZipWithPlus4, validatePhone, validateFax, validateMedicaid, validateNpi, validateNA } from '../../../../constants/validations';
import { FormField } from '../../../common/FormField';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { Typeahead } from 'react-bootstrap-typeahead';

export const AddressForm = (props) => {
    let { address, errors, setAddress, setErrors, showNpiAndMedicare, stateList, countyList, validateNpiChange, childKey } = props;
    const stateInputRef = useRef();
    const countyInputRef = useRef();

    const stateName = stateList.find(state => state.value === address?.state)?.label;
    const countyOptions = countyList && address?.state ? countyList[stateName] : [];
    const [countyRequiredError, setCountyRequiredError] = useState(null);

    const [stateListTypeahead, setStateListTypeahead] = useState(
        address.state
            ? [stateList.find(state => state.value === address.state)]
            : []
    );
    const [countyListTypeahead, setCountyListTypeahead] = useState([address.county])

    useEffect(() => {

        setStateListTypeahead(address.state
            ? [stateList.find(state => state.value === address.state)]
            : [])

        setCountyListTypeahead(address.county? [address.county]:[])


    }, [address]);
    
    const onStateListBlur = () => {
        var text = stateInputRef.current.state.text;
        if (text) {
            var item = stateList.find((s) => { return (s.value.toLowerCase() === text.toLowerCase() || s.label.toLowerCase() === text.toLowerCase()) })
            if (item) {
                onStateListTypeaheadChange([item]);
            }
            else {
                stateInputRef.current.clear();
            }
        }
    }

    const onStateListTypeaheadChange = (selectedState) => {
        setStateListTypeahead(selectedState)

        let newError = '';
        if (selectedState.length === 0) {
            newError = validateForNotNullOrWhitespace(selectedState[0]);
            setErrors({ ...errors, ['state']: newError });
        }
        setErrors({ ...errors, ['state']: newError });

        let field = address;
        field.state = selectedState[0]?.value;
        field.county = '';
        setAddress(field);
        setCountyListTypeahead([])

    }

    const onCountyListBlur = () => {
        var text = countyInputRef.current.state.text;
        if (text) {
            var index = countyOptions.findIndex(item => item.toLowerCase() === text.toLowerCase());
            var item = countyOptions[index];
            if (item) {
                onCountyListTypeaheadChange([item]);
            }
            else {
                countyInputRef.current.clear();
            }
        }
    }
    const onCountyListTypeaheadChange = (selectedCounty) => {
        setCountyListTypeahead(selectedCounty)
        setAddress({
            ...address,
            county: selectedCounty && selectedCounty.length > 0 ? selectedCounty[0] : ""
        });

        setCountyRequiredError(validateForNotNullOrWhitespace(selectedCounty[0]));
    }

    const onFieldChange = (event, field) => {
        let newError = '';
        let newValue = event.target.value;

        if (field === "zip") {
            newError = validateZipWithPlus4(newValue);

            setErrors({ ...errors, [field]: newError });

            let zipCode = '';
            let zipPlus4 = '';

            if (newValue.length < 5) {
                zipCode = newValue;
            }
            else {
                zipCode = newValue.substring(0, 5);
                zipPlus4 = newValue.substring(6);
            }

            setAddress({ ...address, zipCode, zipPlus4 });
            return;
        }
        switch (field) {
            case "streetLine1":
                newError = validateNA(newValue);
                break;
            case "city":
                newError = validateCityName(newValue);
                break;
            case "phone":
                newError = validatePhone(newValue);
                break;
            case "fax":
                newError = validateFax(newValue);
                break;
            case "npi":
                newError = validateNpi(newValue);
                if (newError === '' && newValue) {
                    validateNpiChange(newValue);
                }
                break;
            case "medicaidNumber":
                newError = validateMedicaid(newValue);
                break;
            default:
                break;

        }
        setErrors({ ...errors, [field]: newError });
        setAddress({ ...address, [field]: newValue });
    }

    return <div className="address-form">
        <Row>
            <Col md={12}>
                <FormField type="text"
                    key={"LocationStreetAddr1-"+childKey}
                    required
                    className="required-field"
                    name="LocationStreetAddr1"
                    value={address.streetLine1}
                    label={address.type === "Primary" ? "Street Address" : "Address"}
                    error={errors.streetLine1}
                    autoComplete="off"
                    onChange={(e) => { onFieldChange(e, "streetLine1") }}
                    maxLength={55}
                />
            </Col>
        </Row>
        <Row>
            <Col md={6}>
                <FormField type="text"
                    key={"LocationCity-" + childKey}
                    required
                    className="required-field"
                    name="LocationCity"
                    value={address.city}
                    label="City"
                    error={errors.city}
                    autoComplete="off"
                    onChange={(e) => { onFieldChange(e, "city") }}
                />
            </Col>
            <Col md={6}>
                <Label className="required-field"> State</Label>
                <Typeahead
                    id="stateTypeahead"
                    labelKey="label"
                    onChange={onStateListTypeaheadChange}
                    options={stateList}
                    selected={stateListTypeahead}
                    placeholder="Select State"                  
                    onBlur={onStateListBlur}
                    ref={stateInputRef}
                />
            </Col>
        </Row>
        <Row>
            <Col md={3}>
                <Label className="required-field"> County</Label>
                <Typeahead
                    id="countyTypeahead"
                    labelKey="label"
                    options={countyOptions}
                    onChange={onCountyListTypeaheadChange}
                    selected={countyListTypeahead}                
                    onBlur={onCountyListBlur}
                    ref={countyInputRef}
                />
                {errors.county &&
                    <span className='error'>{errors.county}</span>}
                {countyRequiredError &&
                    <span className='error'>{countyRequiredError}</span>}
            </Col>
            <Col md={3}>
                <FormGroup>
                    <Label className="required-field"> Zip (9 digits)</Label>
                    <InputMask mask="99999-9999" maskChar={null} name="mailingZip" id="LocationZip" key={"LocationZip-" + childKey} value={address.zipCode + "-" + address.zipPlus4}
                        onChange={(e) => { onFieldChange(e, "zip") }} autoComplete="off" className="zipInputMask, form-control" placeholder="XXXXX-XXXX" />
                    {errors.zip &&
                        <span className='error'>{errors.zip}</span>}
                </FormGroup>
            </Col>
            <Col md={3}>
                <FormGroup>
                    <Label className="required-field"> Phone (10 digits)</Label>
                    <InputMask mask="999-999-9999" maskChar={null} name="LocationPhone" key={"LocationPhone-" + childKey}
                        value={address.phone} errors="errors.phone" onChange={(e) => { onFieldChange(e, "phone") }}
                        autoComplete="off" className="phoneInputMask, form-control" placeholder="XXX-XXX-XXXX" />
                    {errors.phone &&
                        <span className='error'>{errors.phone}</span>}
                </FormGroup>
            </Col>
            <Col md={3}>
                <FormGroup>
                    <Label className="fax-label"> Fax (10 digits)</Label>
                    <InputMask mask="999-999-9999" maskChar={null} name="LocationFax"
                        value={address.fax} errors="errors.fax" key={"LocationFax-" + childKey}
                        onChange={(e) => { onFieldChange(e, "fax") }} autoComplete="off" className="phoneInputMask, form-control" placeholder="XXX-XXX-XXXX" />
                    {errors.fax &&
                        <span className='error'>{errors.fax}</span>}
                </FormGroup>
            </Col>
        </Row>
        {showNpiAndMedicare && < Row >
            <Col md={6}>
                <FormField type="text"
                    key={"LocationNpi-" + childKey}
                    required
                    className="required-field"
                    name="LocationNpi"
                    value={address.npi}
                    label={address.type === "Primary" ? "Group NPI" : "NPI"}
                    error={errors.npi}
                    autoComplete="off"
                    onChange={(e) => { onFieldChange(e, "npi") }}
                />
            </Col>
            <Col md={6}>
                <FormField type="text"
                    key={"LocationMedicaidNumber-" + childKey}
                    name="LocationMedicaidNumber"
                    value={address.medicaidNumber}
                    label="Medicaid Number"
                    error={errors.medicaidNumber}
                    autoComplete="off"
                    maxLength={9}
                    onChange={(e) => { onFieldChange(e, "medicaidNumber") }}
                />
            </Col>
        </Row>}
    </div>
}

AddressForm.propTypes = {
    address: PropTypes.object,
    errors: PropTypes.object,
    setAddress: PropTypes.func,
    setErrors: PropTypes.func,
    showNpiAndMedicare: PropTypes.bool,
    stateList: PropTypes.array,
    countyList: PropTypes.array,
    validateNpiChange: PropTypes.func,
    childKey: PropTypes.string
}